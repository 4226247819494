header{
    height: 100vh;
    overflow: hidden;
    padding-top: 4rem;

}
header h1{
    padding-top: 0rem;
    padding-bottom: 0.3rem;
    font-size: 2.7rem;
    font-weight: 700;
}
.changing__text{
    font-size: 1.2rem;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.8rem;
    position: absolute;
    left: 0;
    bottom: 2.5rem;
}

.me{
    background: linear-gradient(var(--color-primary),transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left:50%;
    /* bottom:-3rem; */
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0 ;
    overflow: hidden;
    padding:3rem 0rem 0rem 0rem;
}
.header-img-me{
    width: 20rem;
    transform: translateX(10%) translateY(-5%);
}
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 400;
    /* font-size: 0.9rem; */
}

/* @media screen and (max-width:1024px) {
    header{
        height: 88vh;
    }
} */

@media screen and (max-width:600px) {
    header{
        height: 100vh;
    }
    .header__socials,.scroll__down{
        display: none;
    }
    .header-img-me{
        width: 17rem;
        transform: translateX(20%)  translateY(-10%);
    }
}