.experience__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
section>h4{
    text-align: center;
    color: var(--color-light);
    padding-top: 3rem;
}
section>h2{
    padding-bottom: 0.7rem;
    font-weight: 600;
}

.experience__container > div {
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 2px solid ;
    
    background: transparent;
    border-color: var(--color-primary-variant);
    transition: var(--transition);
}
.experience__container > div:hover {
    background: var( --color-bg-variant);
    
    border: 2px solid transparent;
    cursor: default;
}
.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}
.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}
.experienced__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }
    .experience__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
}
@media screen and (max-width: 600px) {
    .experience__container {
        gap:1rem;
    }
    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}